import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {PRIMARY_COLOR, PRIMARY_COLOR_1, TEXT_COLOR} from '../constants';

const List = function ({children}) {
	return <StyledList>{children}</StyledList>;
};

List.propTypes = {
	children: PropTypes.node
};

const StyledList = styled.ul`
	display: block;
	width: 100%;
	line-height: 1.6;
	margin: 1.5rem 0;
	font-size: 1rem;
	color: ${TEXT_COLOR};
	text-align: start;
	padding: 0 0 0 1.05rem;
	list-style-position: outside;

	a {
		border-bottom: 2px solid ${PRIMARY_COLOR};
		color: ${TEXT_COLOR};
		transition: border-bottom-color 100ms ease;

		&:hover {
			border-bottom-color: ${PRIMARY_COLOR_1};
		}
	}

	code {
		color: ${PRIMARY_COLOR};
		font-size: 1.06em;
	}
`;

export default List;
