import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {CONTAINER_BLOG_WIDTH, CONTAINER_WIDTH} from '../constants';
import {getStyleForMargins} from '../helpers';

export default function Container({children, width = null, m = null, mt = null, mr = null, mb = null, ml = null}) {
	return (
		<StyledContainer $width={width} $m={m} $mt={mt} $mr={mr} $mb={mb} $ml={ml}>
			{children}
		</StyledContainer>
	);
}

Container.propTypes = {
	children: PropTypes.node,
	width: PropTypes.oneOf(['standard', 'blog']),
	// margins:
	m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const StyledContainer = styled.div`
	display: block;
	width: 100%;
	max-width: ${({$width}) => ($width === 'blog' ? CONTAINER_BLOG_WIDTH : CONTAINER_WIDTH)};
	padding: 0 1rem;
	box-sizing: border-box;
	margin: 0 auto;

	${({$m, $mt, $mr, $mb, $ml}) => css(getStyleForMargins({m: $m, mt: $mt, mr: $mr, mb: $mb, ml: $ml}))};
`;
