import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/Layout';
import IntroImage from '../ui/IntroImage';
import Container from '../ui/Container';
import Paragraph from '../ui/Paragraph';
import Title from '../ui/Title';
import List from '../ui/List';
import Footer from '../components/Footer';
import SignUpCTA from '../components/SignUpCTA';
import Nav from '../components/Nav';
import {PRIMARY_COLOR_2} from '../constants';
import EnvData from '../components/EnvData';

export default function HoteliPage() {
	return (
		<EnvData>
			<ThePage />
		</EnvData>
	);
}

const ThePage = function () {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq: "hotel/room.jpg"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			title="QR Cjenik za hotele"
			description="QR Cjenik je idealni proizvod za goste hotela kako bi dobili brzu i ažurnu informaciju o dostupnim uslugama"
		>
			<Nav background={PRIMARY_COLOR_2} />
			<IntroImage title="QR Cjenik za hotele" image={data.file.childImageSharp.fluid} />
			<Container mt="2rem" width="blog">
				<Paragraph>
					Velika većina hotela još uvijek u sobi na komadu papira ili u nekoj knjižici ima popis dodatnih usluga koji nudi, zajedno s nekim
					drugim servisnim informacijama. Sve te informacije su obično duplicirane onoliko puta koliko jezika ste preveli.
				</Paragraph>
				<Paragraph>
					Dođe li do bilo kakve promjene u tim informacijama, nerijetko se dogodi da se stavke samo prekriže ili se nekako drugačije
					zamaskiraju kako se više ne bi vidjele. Što vam je broj soba veći, to je taj postupak teži. Ukoliko se odlučite za izradu novih
					papira ili knjižica, tada taj postupak postaje i skuplji.
				</Paragraph>
				<Title fontSize="1.5rem" mt="2.5rem">
					QR Cjenik je - više od cjenika
				</Title>
				<Paragraph>
					Osim cijena dodatnih usluga, u vaš cjenik možete postaviti dodatne informacije koristeći tekst, slike ili video. Informacije
					možete uvijek ažurirati, koliko god puta želite. Ako nemate vremena ulogirati se u QR Cjenik da bi promijenili informacije, a
					znate unaprijed što bi vaši gosti trebali znati na određeni dan i/ili vrijeme, onda iskoristite opcije vremenskog ciljanja.
				</Paragraph>
				<Paragraph>
					Sve elemente vašeg cjenika možete postaviti na način da se prikažu na točno određene datume i vrijeme. Ovo vam omogućava, npr., da
					na jednostavan način prikažete izmjenjeno radno vrijeme kuhinje na određeni blagdan ili da kreirate menije za doručak/ručak/večeru
					za svaki dan.
				</Paragraph>
				<Paragraph>Osim vremenskog ciljanja, možete postaviti da se elementi periodično vide:</Paragraph>
				<List>
					<li>svaki dan u određeno vrijeme (npr. happy hour)</li>
					<li>određeni dan u tjednu, svaki tjedan - ako imate menije koji se ponavljaju svaki tjedan, onda je ovo opcija za Vas</li>
					<li>određeni dan u mjesecu, svaki mjesec</li>
				</List>
				<Title fontSize="1.5rem" mt="2.5rem">
					Prevedite - sve
				</Title>
				<Paragraph>
					Sav sadržaj (cijene, tekstovi, naslovi, slike, pa i ubačeni videi) se mogu prevesti na sve jezike koje odaberete. Iskoristite naš
					sustav za strojno prevođenje sa Hrvatskog na sve ostale jezike kako bi ubrzali prevođenje, samo imajte u vidu da ono što dobijete
					prevedeno od strane sustava svakako treba pregledati osoba koja zna jezik.
				</Paragraph>
				<Paragraph>
					Kada vaš gost skenira QR kȏd, naš sustav će pokušati detektirati jezik od uređaja koji je skenirao kȏd. Ukoliko se to može
					detektirati i detektira se jezik koji vi imate preveden, automatski će se prikazati jezik vašeg gosta bez da je potrebno išta
					dodatno napraviti.
				</Paragraph>
			</Container>
			<SignUpCTA mt="5rem" />
			<Footer />
		</Layout>
	);
};
