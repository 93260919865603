import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

export default function IntroImage({title, image, children = null}) {
	return (
		<StyledIntroImage>
			{title && <h1>{title}</h1>}
			{children && <blockquote>{children}</blockquote>}
			<Img fluid={image} alt="" objectFit="cover" objectPosition="bottom center" className="img" />
		</StyledIntroImage>
	);
}

IntroImage.propTypes = {
	title: PropTypes.string,
	// eslint-disable-next-line
	image: PropTypes.object.isRequired,
	children: PropTypes.node
};

// const kf = keyframes`
//   0% {
//     transform: translateZ(-1400px) translateY(800px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateZ(0) translateY(0);
//     opacity: 1;
//   }
// `;

// animation: ${kf} 2.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

const StyledIntroImage = styled.div`
	display: block;
	overflow: hidden;
	position: relative;

	.img {
		max-height: 700px;
	}

	> blockquote {
		margin: 0 auto;
		max-width: 780px;
	}

	> h1 {
		color: #222222;
		display: block;
		text-align: center;
		padding: 1rem;
		box-sizing: border-box;
		//background: rgba(0, 0, 0, 0.4);
		margin: 1rem 0;
		//position: absolute;
		//left: 0;
		//right: 0;
		//text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.9);
		//bottom: 12%;
		font-size: 1.5rem;

		@media (min-width: 767px) {
			//bottom: 22%;
			font-size: 1.75rem;
		}

		@media (min-width: 992px) {
			//bottom: 32%;
			font-size: 2rem;
		}

		@media (min-width: 1200px) {
			//bottom: 42%;
			font-size: 2.5rem;
		}
	}
`;
